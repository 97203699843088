import { useEffect, useState } from "react"

/**
 * Check if the passed ref is visible or not
 * @param {*} observableRef ref to observe
 * @returns
 */
export function useIsVisible(observableRef) {
    const [isIntersecting, setIntersecting] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))

        observer.observe(observableRef.current)
        return () => {
            observer.disconnect()
        }
    }, [observableRef])

    return isIntersecting
}
