import React from "react"

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { Fab } from "@mui/material"
import { useIsVisible } from "../hooks/useIsVisible"

/**
 * floating button that send the page to the top
 */
function GoTopButton({ refScrollUp }) {
    const isVisible = useIsVisible(refScrollUp)
    /**
     * Handel the scroll click
     */
    const handleOnClick = () => {
        refScrollUp?.current?.scrollIntoView({ behavior: "smooth" })
    }

    return isVisible ? null : (
        <Fab aria-label='Back to top' className='btn-action-button' onClick={handleOnClick}>
            <KeyboardArrowUpIcon />
        </Fab>
    )
}

export default GoTopButton
