import React from "react"

/**
 * Creates a single meta tag
 * @param {string|undefined} name the name to use use or undefined
 * @param {string|undefined} property the property to use use or undefined
 * @param {string|undefined} content the content to use, to empty no tag will be rendered
 * @param {bool} noIndex
 * @returns
 */
function metaTag(name, property, content, noIndex = false) {
    if (noIndex || !content) return null
    if (name) return { name, content }
    if (property) return { property, content }
    return null
}

function MetaData({ title = undefined }) {
    // NOTE: the below will be converted into <meta/> tags
    // maybe better we should use useMemo here?
    // .filter((n) => n)  at the end if to remove nulls
    const metaTags = [
        // Basic meta tags
        metaTag("robots", undefined, "noindex, noimageindex, nofollow, noarchive, nocache, nosnippet, noodp, noydir"),
    ].filter((n) => n)

    // test this at - https://www.opengraph.xyz/
    return (
        <>
            {title && <title>{`${title} | Support Tickets`}</title>}
            {metaTags.map((tag) => {
                return tag.name ? <meta name={tag.name} content={tag.content} /> : <meta property={tag.property} content={tag.content} />
            })}
        </>
    )
}

export default MetaData
